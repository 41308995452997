import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { GoodItem } from './GoodItem';
import { GoodsPrice } from './GoodsPrice';
import { fromStore } from '../../../selectors';
import { Loader } from '../../../components';
import { getIdGood } from '../../../utils';

export function GoodsList({ order }) {
  const loader = useSelector(fromStore.goodsLoaderSelector);
  const goods = useSelector(fromStore.goodsEntitiesSelector);

  if (loader) return <Loader list />;

  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
        <thead>
          <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
            <th className="min-w-175px">Товар</th>
            <th className="min-w-70px text-end">Кол-во</th>
            <th className="min-w-100px text-end">Цена</th>
            <th className="min-w-100px text-end">Всего</th>
          </tr>
        </thead>
        <tbody className="fw-bold text-gray-800">
          {order.goods.map((good) => (
            <GoodItem
              key={good.id}
              hasInList={!isEmpty(good.isMod ? goods[getIdGood(good.id)] : goods[good.id])}
              good={good}
            />
          ))}
          <GoodItem unit={order.unit} />
          <GoodsPrice order={order} />
        </tbody>
      </table>
    </div>
  );
}

GoodsList.propTypes = {
  order: PropTypes.object.isRequired,
};
